import { useParams } from "react-router-dom";
import Articles from "../data/articles";
import "./style.css";

// Article page component
const ArticlePage = () => {

    const { articleId } = useParams();
    const article = Articles.find(article => article.articleId === articleId);

    if (!article) {
        return <p>Article not found</p>;
    }
    
    return (
        
        <div className="article-page" id="article-page">
            <img
                src={article.articleThumbnailURL}
                alt="Article thumbnail"
                className="article-page-thumbnail"
                id="article-page-thumbnail"
            />
            <h1 className="article-page-title" id="article-page-title">
                {article.articleTitle}
            </h1>
            <p className="article-page-date" id="article-page-date">
                {article.articleProfileDate}
            </p>
            <div className="article-profile">
            <img
                src={article.articleProfileImage}
                alt="Author"
                className="article-page-author-image"
                id="article-page-author-image"
            />
            <div className="article-page-author-info" id="article-page-author-info">
                <div className="article-page-author-name" id="article-page-author-name">
                    {article.articleProfileName}
                </div>
            </div>
            </div>
            <div className="article-page-content" id="article-page-content">
                {article.articleContent}
            </div>
        </div>
    );
}

export default ArticlePage;
