// Import components
import TopBlogsPostContainer from "./TopBlogPosts";

// Blog posts container
export default function BlogPostsContainer() {
    return (
        <div className="blog-posts-container" id="blog-posts-container">
            <div className="blog-posts-container_top-blogs-container" id="blog-posts-container_top-blogs-container">
                <div className="top-blogs-category-title" id="top-blogs-category-title">
                    Featured Blogs
                </div>
                <TopBlogsPostContainer />
            </div>
        </div>
    );
}
