import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

// Import assets and styles
import "./style.css";
import "./responsive.css";
import Drone1 from "../../assets/drone-image-one.jpeg";
import Drone2 from "../../assets/drone-image-two.jpeg";

// About component function
export default function AboutUS() {
    // State to manage paragraph visibility
    const [isExpanded, setIsExpanded] = useState({
        firstParagraph: false,
        secondParagraph: false
    });

    useEffect(() => {
        // Set title
        document.title = "About US - Sudarshan Aerial Solutions Pvt. Ltd";
        
        // Scroll to top smoothly on mount
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    // Toggle visibility function
    const toggleParagraph = (paragraph) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [paragraph]: !prevState[paragraph]
        }));
    };

    // Return UI
    return (
        <section className="about-section" id="about-section">
            <div className="about-heading-container" id="about-heading-container">
                About US
            </div>
            <div className="about-heading-container-two">
                We are Sudarshan Aerial Solutions Pvt. Ltd.
            </div>
            <div className="contact-us-button-container">
                <NavLink to="/contact-us" end className={({ isActive }) => (isActive ? "active-link" : "")}>
                    <button className="contact-us-button">
                        Contact US
                    </button>
                </NavLink>
            </div>
            <div className="about-us-content">
                <img src={Drone1} className="about-us-image" alt="Drone 1" />
                <p>
                    Sudarshan Aerial Solutions Pvt. Ltd. is a dynamic and innovative startup dedicated to revolutionizing the field of drone technology. 
                    {isExpanded.firstParagraph && (
                        <span className="extra-paragraph" style={{
                            marginTop: 0,
                            marginLeft: 8
                        }}>
                            Founded recently, our company is driven by a passion for innovation and a commitment to providing cutting-edge solutions in the rapidly evolving drone and UAV (Unmanned Aerial Vehicle) segment. At Sudarshan Aerial Solutions, we specialize in the design, manufacturing, and customization of high-quality drones tailored to meet the diverse needs of our clients. Whether it's for aerial photography, surveillance, mapping, agriculture, or industrial inspection, our drones are engineered to deliver exceptional performance, reliability, and versatility.
                        </span>
                    )}
                    <span className="read-more-span" onClick={() => toggleParagraph('firstParagraph')}>
                        {isExpanded.firstParagraph ? "Read less" : "Read more"}
                    </span>
                </p>
            </div>
            <div className="about-heading-container two-m" id="about-heading-container">
                Our Vision
            </div>
            <div className="about-us-content">
                <img src={Drone2} className="about-us-image" alt="Drone 2" />
                <p>
                    Our vision is to redefine the future of industries through pioneering drone technology solutions.
                    {isExpanded.secondParagraph && (
                        <span className="extra-paragraph" id="extra-paragraph">
                            We aspire to be the leading innovator in the drone industry, leveraging cutting-edge technology to create transformative solutions that drive efficiency, safety, and sustainability across various sectors. With a relentless commitment to excellence and a passion for innovation, we envision a world where drones empower businesses and organizations to achieve new heights of success, while making a positive impact on society and the environment.
                        </span>
                    )}
                    <span className="read-more-span" onClick={() => toggleParagraph('secondParagraph')}>
                        {isExpanded.secondParagraph ? "Read less" : "Read more"}
                    </span>
                </p>
            </div>
        </section>
    );
}
