// Import assets and styles
import "./style.css";
import RecImg from "../../assets/seed-funding.jpeg"

// Recognization component
export default function Recognization() {
    return(
        <section className="reconginazation-section">
            <div className="reconginazation-section_primary-heading">
                Recognizations
            </div>
            <div className="reconginazation-section_content-heading">    
                Seed Fund of ₹50,000 from Magic Incubation under REMS Program.
            </div>
            <img src={RecImg}></img>
            <p>
            We are thrilled to announce that Sudarshan Aerial Solutions Pvt. Ltd. has been selected as a recipient of a seed fund of 50,000 rupees from Magic Incubation under the REMS Program.
            This seed fund allocation is a significant milestone for Sudarshan Aerial Solutions, as it provides crucial financial support to fuel our early-stage growth and development. The funding will be instrumental in accelerating our efforts to innovate, expand our product offerings, and establish a strong foundation for long-term success. 
            </p>
        </section>
    )
}