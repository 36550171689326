import "./style.css";

// Footer component
export default function Footer() {
    return(
        <footer className="footer-section" id="footer-section">
            <div className="copyright-holder">
                © Copyright 2024 Sudarshan Aerial Solutions Pvt. Ltd. All Rights Reserved.
            </div>
        </footer>
    );
}