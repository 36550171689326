import React, { useEffect } from 'react';

// Importing styles amd assets
import './style.css';
import softwareSolutionsImg from '../../assets/software-solutions.svg';
import accessoriesAddonsImg from '../../assets/accessories.svg';
import trainingSupportImg from '../../assets/training-and-supports.svg';

const OurOfferings = () => {
    useEffect(() => {
        // Set the title of the page
        document.title = "Our Offerings - Sudarshan Aerial Solutions Pvt. Ltd";
        // Scroll to top smoothly on mount
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <section className="our-offering">
            <div className="our-offering-headline">
                Our Offerings
            </div>
            <div className="our-offering-headling-two">
                These are the services that we provide.
            </div>
            <div className="container">
                <div className="section">
                    <img src={softwareSolutionsImg} alt="Software Solutions" />
                    <h2>Software Solutions</h2>
                    <p>
                    Alongside our hardware offerings, we provide software solutions to
complement our drones and enhance their functionality. Our software
solutions include flight planning software, data processing and analysis tools,
and custom software applications tailored to specific industry needs. These
software solutions enable clients to optimize flight routes, capture and
process data more efficiently, and extract valuable insights from aerial
imagery and sensor data.
                    </p>
                </div>

                <div className="section">
                    <img src={accessoriesAddonsImg} alt="Accessories and Add-Ons" />
                    <h2>Accessories and Add-Ons</h2>
                    <p>
                    Sudarshan Aerial Solutions also offers a range of accessories and add-ons to
complement our drones and enhance their usability. This includes items such
as spare batteries, chargers, propellers, carrying cases, and protective covers.
Additionally, we provide add-on modules and upgrades to expand the
capabilities of our drones, such as obstacle avoidance systems, GPS trackers,
and communication modules.
                    </p>
                </div>

                <div className="section">
                    <img src={trainingSupportImg} alt="Training and Support Services" />
                    <h2>Training and Support Services</h2>
                    <p>
                    To ensure that our clients can make the most of their drone investments, we
offer comprehensive training and support services. Our training programs
cover topics such as drone operation, flight safety, maintenance, and
regulatory compliance. Additionally, our support services include technical
assistance, troubleshooting, and maintenance contracts to keep our clients'
drones in top condition and minimize downtime.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default OurOfferings;
