import React from 'react';
import './style.css';  // Ensure the path to your stylesheet is correct
import Product1 from "../../assets/product-1.png";

const ProductPage = () => {

    // Function to open WhatsApp
    return (
        <div className="products-section">
            <div className="launching-soon">
                {/* Image Showcase */}
                <div className="image-showcase">
                    <img
                        src={Product1}
                        alt="30A BLDC ESC - Electronic Speed Controller"
                        className="showcase-image"
                    />
                </div>
                
                <h1 className="launching-soon-one">30A BLDC ESC</h1>
                <div style={
                    {
                        marginTop: "20px",
                        marginBottom: "30px",
                        fontSize: "20px"
                    }
                }><b>Price: </b>  450 RS </div>
                <p className="launching-soon-two">
                    Electronic Speed Controller <br/>
                    for Brushless Motors
                </p>

                {/* WhatsApp Contact Button */}
                <div className="whatsapp-contact-container">
                    <a 
                        href="https://wa.me/9356983825" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="whatsapp-contact-button"
                    >
                        Buy with WhatsApp
                    </a>
                </div>

                <h2>Specifications:</h2>
                <ul className="product-specifications">
                    <li>Current: 30A</li>
                    <li>Input Voltage: 2-4S LiPo (7.4V to 14.8V)</li>
                    <li>Output Type: 3-Phase AC</li>
                    <li>Control Signal: PWM (Pulse Width Modulation)</li>
                    <li>Supports: BLDC motors (sensorless)</li>
                    <li>Features: Over-current protection, low voltage cut-off, overheat protection</li>
                    <li>Dimensions: 45mm x 24mm x 11mm</li>
                    <li>Weight: 28g</li>
                    <li>Connections: Bullet connectors (3.5mm) for motor, XT60 connector for battery</li>
                </ul>
                <h2>Description:</h2>
                <p>
                    The 30A BLDC ESC is an electronic speed controller designed for brushless DC motors. 
                    It supports 2-4S LiPo batteries and provides smooth control for high-performance motors, 
                    making it ideal for drone, RC plane, and other hobbyist applications.
                </p>
                <p>
                    This ESC comes with multiple protection features, including over-current protection, 
                    low voltage cut-off, and overheat protection, ensuring the longevity and safety of your electronics.
                </p>
            </div>
        </div>
    );
};

export default ProductPage;
