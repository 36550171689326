// Import neccessary packages
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

// Import styles and assets
import "./style.css";
import "./res.css";
import "./animations.css";
import droneImage from "../../assets/drone-image.png"

// Import layouts
import AboutUS from "../about-page/layout";
import TeamOverview from "../team-overview/layout";
import OurOfferings from "../our-offerings/layout";
import Recognization from "../recognization/layout";

// Landing page
export default function LandingPage() {

    useEffect(() => {
        // Set title
        document.title = "Home - Sudarshan Aerial Solutions Pvt. Ltd";

        // Scroll to top smoothly on mount
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    // Return UI
    return (
        <>
            <section className="landing-page" id="landing-page">
                <div className="background-container" id="background-container">
                    <div className="background-image" style={{ backgroundImage: `url("https://res.cloudinary.com/dkloamkhh/image/upload/v1723629398/background-image_p60ve6.png")` }}
                        id="background-image" />
                    <div className="landing-page-container" id="landing-page-container">
                        <div className="headings-container" id="headings-container">
                            <div className="heading-one" id="heading-one">
                                <span>COME FLY</span>
                                <br />
                                <img
                                    src={droneImage}
                                    alt="Drone"
                                    className="drone-image"
                                />  <br />
                                <span> WITH US</span>
                            </div>
                            <div className="heading-two" id="heading-two">
                                Transforming the Skies with Cutting-Edge Drone Technology.
                            </div>
                            <div className="heading-three">
                                At Sudarshan Aerial Solutions, we specialize in the design, manufacturing, and customization of high-quality drones tailored to meet the diverse needs of our clients.
                            </div>
                            <div className="landing-buttons-container" id="landing-buttons-container">
                                <NavLink to="/about-us" end className={({ isActive }) => (isActive ? "active-link" : "")}>
                                    <button className="know-more-button" id="know-more-button">
                                        Know More
                                    </button>
                                </NavLink>
                                <NavLink to="/contact-us" end className={({ isActive }) => (isActive ? "active-link" : "")}>
                                    <button className="get-in-touch-button-home" id="get-in-touch-button-home">
                                        Contact US
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OurOfferings />
            <AboutUS />
            <TeamOverview />
            <Recognization />
        </>
    )
}