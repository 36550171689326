// Import neccessary packages
import { useEffect } from "react";

// Import styles and assets
import "./style.css";
import "./res.css";

// Import layouts
import MemberProfiles from "./MemberProfiles";
import Mentors from "./Mentors";

// Team overview component
export default function TeamOverview() {

    useEffect(() => {
        // Set title
        document.title = "Meet Our Team - Sudarshan Aerial Solutions Pvt. Ltd";
        
        // Scroll to top smoothly on mount
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);


    // Return UI
    return (
        <section className="team-overview-section" id="team-overview-section">
            <div className="team-overview-heading" id="team-overview-heading">
                <div className="team-overview-heading one">Meet Our Team</div>
                <div className="team-overview-heading two">This is our incredible team who's working behind the scenes and making everything happen.</div>
            </div>
            <div className="team-members-profiles-container_bg">
                <div className="team-members-profiles-container" id="team-members-profiles-container">
                    <MemberProfiles />
                </div>
            </div>
            <div className="team-overview-heading" id="team-overview-heading">
                <div className="team-overview-heading one">Meet Our Mentors</div>
                <div className="team-overview-heading two">These are our mentors and motivators, who encourage us to make this happen.</div>
            </div>
            <div className="team-members-profiles-container_bg">
                <div className="team-members-profiles-container-two" id="team-members-profiles-container">
                    <Mentors />
                </div>
            </div>
        </section>
    );
}
